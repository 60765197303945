import { Box, Button, Container, Heading, Text } from '@chakra-ui/react'
import Head from 'next/head'
import PortalThemedProvider from '../components/shared/portal/PortalThemedProvider'

const Page404 = () => {
  return (
    <>
      <Head>
        <title>EPFL Innovation Park</title>
        <meta name='description' content='You seems lost!' />
        <meta name='theme-color' content='#E53E3E' />
      </Head>

      <PortalThemedProvider>
        <ErrorPage />
      </PortalThemedProvider>
    </>
  )
}

const ErrorPage = () => {
  return (
    <Box
      bgRepeat='no-repeat'
      bgImage='url(https://global-uploads.webflow.com/627cb49ee9c8c230cfca9d37/627cb49ee9c8c2d326ca9da5_raw-header-green-trans.png)'
      bgPos='0 0'
      bgSize='100% 100%'>
      <Container maxW='container.xl' h='100vh'>
        <Box
          h='full'
          display='flex'
          placeItems='center'
          justifyContent='center'
          flexDirection='column'
          gap={10}>
          <Text fontSize='200px' color='red.500' fontWeight='extrabold'>
            404
          </Text>
          <Heading textAlign='center' w='full' as='h1' fontWeight='bold'>
            Ooops! The page can't be found
          </Heading>
          <Button size='lg' colorScheme='red' variant='outline' as='a' href='/'>
            Take me back to the home page
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

// noinspection JSUnusedGlobalSymbols
export default Page404
